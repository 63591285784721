function App() {
  return (
    <div className="App">
      <div className="header">
        <div  className="container">
          <h1 className="brand">dunkeldorff</h1>
        </div>
      </div>
      <div className="main">
        <div className="container">
          <h2>Private Equity Management</h2>
        </div>
        <div className="container">
          <div className="content">
            <p>
              <h4>Inquiries</h4>
            </p>
          </div>
          <table>
            <tr>
              <td>Investors: </td>
              <td>investors@dunkeldorff.com</td>
            </tr>
            <tr>
              <td>Assets:</td>
              <td>assets@dunkeldorff.com</td>
            </tr>
            <tr>
              <td>Partners:</td>
              <td>pdb@dunkeldorff.com</td>
            </tr>
            <tr>
              <td>General:</td>
              <td>info@dunkeldorff.com</td>
            </tr>
          </table>
        </div>
        <div className="container">
          <p className="copy">
            dunkeldorff co. Ltd.<br />
             吨颗都付集團有限公司<br />
             &copy; 2021
          </p>
          <ul>
              <li>San Francisco</li><li>New York</li><li>London</li><li>Amsterdam</li><li>Singapore</li><li>杭州</li><li>深圳</li><li>東京</li>
            </ul>
        </div>
      </div>
    </div>
  );
}

export default App;
